<template>
  <div class="header f-sb-c mrow-240">
    <img src="~@/assets/logo.png" class="logo" />
    <div>
      <nav class="nav">
        <!-- <div class="marker" :style="markerStyle"></div> -->
        <a
          class="item mrow-40"
          :class="$route.path === '/official' && 'selected'"
          :href="`${base}/official/index`"
          >首页</a
        >
        <a
          class="item mrow-40"
          :class="$route.path === '/news' && 'selected'"
          :href="`${base}/official/news`"
          >新闻</a
        >
        <a
          class="item mrow-40"
          :class="$route.path === '/about' && 'selected'"
          :href="`${base}/official/about`"
          >关于我们</a
        >
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    base() {
      const router = this.$router;
      const base = router.options.base || '';
      return base;
    }
    // markerStyle() {
    //   return '';
    // }
  },
  created() {
    console.log(this.$route);
  }
};
</script>

<style lang="scss" scoped>
.mrow-240 {
  margin-right: 240px;
  margin-left: 240px;
}
.logo {
  width: 260px;
}

.nav {
  position: relative;
  display: flex;
  .item {
    position: relative;
    font-size: 2em;
    color: #000;
    text-decoration: none;
  }
  .selected {
    color: #415385;
  }
  // .marker {
  //   position: absolute;
  //   left: 0;
  //   height: 4px;
  //   width: 0;
  //   background: #000;
  //   bottom: -8px;
  //   border-radius: 4px;
  // }
}
</style>
