import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from '@/layout';
import { AppMain } from '@/layout/components';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/official',
    children: [
      {
        path: '/official',
        redirect: '/official/index'
      },
      {
        path: '/official/index',
        name: 'Official',
        component: () => import('@/views/Official.vue'),
        meta: {
          title: '幼享乐 | 记录 ·分享·幼儿快乐'
        }
      },
      {
        path: '/official/news',
        name: 'News',
        component: () => import('@/views/News.vue'),
        meta: {
          title: '幼享乐 | 新闻'
        }
      },
      {
        path: '/official/about',
        name: 'About',
        component: () => import('@/views/About.vue'),
        meta: {
          title: '幼享乐 | 关于我们'
        }
      }
    ]
  },
  {
    path: '/app',
    component: AppMain,
    redirect: '/app/system/contact',
    children: [{
      path: '/app/launch-app',
      name: 'LaunchApp',
      component: () => import('@/views/app/launch-app.vue'),
      meta: {
        title: '幼享乐 | 用户协议与隐私政策'
      }
    }, {
      path: '/app/system/child-privacy-statement',
      name: 'ChildPrivacyStatement',
      component: () => import('@/views/app/system/child-privacy-statement.vue'),
      meta: {
        title: '幼享乐 | 儿童隐私保护声明'
      }
    }, {
      path: '/app/system/service_agreement',
      name: 'ServiceAgreement',
      component: () => import('@/views/app/system/ServiceAgreement.vue'),
      meta: {
        title: '幼享乐 | 服务协议'
      }
    }, {
      path: '/app/system/privacy_policy',
      name: 'PrivacyPolicy',
      component: () => import('@/views/app/system/PrivacyPolicy.vue'),
      meta: {
        title: '幼享乐 | 隐私政策'
      }
    }, {
      path: '/app/system/community_norms',
      name: 'CommunityNorms',
      component: () => import('@/views/app/system/CommunityNorms.vue'),
      meta: {
        title: '幼享乐 | 社区规范'
      }
    }, {
      path: '/app/system/apply-kindergarten',
      name: 'ApplyKindergarten',
      component: () => import('@/views/app/system/apply-kindergarten.vue'),
      meta: {
        title: '幼享乐 | 申请场景认证协议'
      }
    }, {
      path: '/app/system/notice',
      name: 'Notice',
      component: () => import('@/views/app/system/Notice.vue'),
      meta: {
        title: '幼享乐 | 申请权限须知'
      }
    }, {
      path: '/app/system/help/info',
      name: 'HelpInfo',
      component: () => import('@/views/app/system/help/Index.vue'),
      meta: {
        title: '幼享乐 | 帮助'
      }
    }, {
      path: '/app/system/contact',
      name: 'Contact',
      component: () => import('@/views/app/system/Contact.vue'),
      meta: {
        title: '幼享乐 | 联系我们'
      }
    }, {
      path: '/app/mine/check_in_help',
      name: 'CheckInHelp',
      component: () => import('@/views/app/mine/CheckInHelp.vue'),
      meta: {
        title: '幼享乐 | 帮助说明'
      }
    }, {
      path: '/app/mine/wallet_help',
      name: 'WalletHelp',
      component: () => import('@/views/app/mine/WalletHelp.vue'),
      meta: {
        title: '幼享乐 | 钱包帮助'
      }
    }, {
      path: '/app/mine/photo-download',
      name: 'WalletHelp',
      component: () => import('@/views/app/mine/photo-download.vue'),
      meta: {
        title: '幼享乐 | 照片下载使用协议'
      }
    }, {
      path: '/app/login/license_agreement',
      name: 'LicenseAgreement',
      component: () => import('@/views/app/login/LicenseAgreement.vue'),
      meta: {
        title: '幼享乐 | 授权协议'
      }
    }]
  },
  {
    path: '/h5',
    component: AppMain,
    redirect: '/h5/download',
    children: [{
       path: '/h5/kindergarten',
       name: 'kindergarten',
       component: () => import('@/views/h5/kindergarten/Index.vue'),
       meta: {
         title: '幼享乐 | 场景详情'
       }
    }, {
      path: '/h5/activity',
      name: 'activity',
      component: () => import('@/views/h5/activity/Index.vue'),
      meta: {
        title: '幼享乐 | 活动详情'
      }
    }, {
      path: '/h5/activity/info',
      name: 'activityInfo',
      component: () => import('@/views/h5/activity/DateInfo.vue'),
      meta: {
        title: '幼享乐 | 活动详情'
      }
    }, {
      path: '/h5/tweet',
      name: 'Tweet',
      component: () => import('@/views/h5/tweet/Item.vue'),
      meta: {
        title: '幼享乐 | 资讯详情'
      }
    }, {
      path: '/h5/invite',
      name: 'Invite',
      component: () => import('@/views/h5/invite/Index.vue'),
      meta: {
        title: '幼享乐 | 立刻邀请'
      }
    }, {
      path: '/h5/download',
      name: 'Download',
      component: () => import('@/views/h5/download/Index.vue'),
      meta: {
        title: '幼享乐 | 下载应用'
      }
    }]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/yxl-web',
  routes
});

export default router;
