import { Toast } from 'vant';

const info = (msg) => {
  Toast(msg);
};

const success = (msg) => {
  Toast.success(msg);
};

// const warning = msg => {
//   Message({
//     message: msg,
//     type: 'warning',
//     duration: 5 * 1000
//   });
// };

const error = (msg) => {
  Toast.fail(msg);
};

export default { success, info, error };
