import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/common.scss'; // global css

import Vant from 'vant';
import 'vant/lib/index.css';

import message from '@/utils/message';

Vue.use(Vant);

Vue.config.productionTip = false;

// 提示
Vue.prototype.$success = message.success;
Vue.prototype.$error = message.error;

router.beforeEach((to, from, next) => {
  if (to.meta) {
    document.title = (to.meta && to.meta.title) || '幼享乐';
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
